import Axios from "axios";
import React from "react";
import domain from "../../util/domain";
import "./Snippet.scss";

function Snippet({ snippet, getSnippets, editSnippet }) {
  async function deleteSnippet() {
    if (window.confirm("Do you want to delete this website?")) {
      await Axios.delete(`${domain}/snippetwebsites/${snippet._id}`);

      getSnippets();
    }
  }

  return (
    <div className="snippet">
        <div className="snippet-padding">

            {snippet.title && <h2 className="title">{snippet.title}</h2>}
          
          <div className="details-div">
                {snippet.description && (
                  <p className="description">{snippet.description}</p>
                )}
                {snippet.code && (
                  <pre className="code">
                    <code>{snippet.code}</code>
                  </pre>
                )}

              <div className="snippet-buttons">
                <button className="btn-edit" onClick={() => editSnippet(snippet)}>
                  Edit
                </button>
                <button className="btn-delete" onClick={deleteSnippet}>
                  Delete
                </button>
              </div>
            </div>
        </div>
    </div>
  );
}

export default Snippet;
