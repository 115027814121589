import React from 'react';
import Navbar from "./Navbar";
import "./policy.css";


function Policy() {
    return (
        
    <div className="main_wrapper">
        <Navbar/>
        <div className="policy_wrapper">
            
            <div className="policy_div">
                <div className="policy_padding">

                <h2>GDPR</h2><br/>

                <b>Introduction:</b>

                <p>
                The EU General Data Protection Regulation (“GDPR”) came into force across the European Union on 25th May 2018 and brought with it the most significant changes to data protection law in two decades. Based on privacy by design and taking a risk-based approach, the GDPR has been designed to meet the requirements of the digital age.<br/>
                The 21st Century brings with it broader use of technology, new definitions of what constitutes personal data, and a vast increase in cross-border processing. The new Regulation aims to standardise data protection laws and processing across the EU; affording individuals stronger, more consistent rights to access and control their personal information.

                </p><br/>

                <b>
                Our commitment:
                </b>

                <p>
                http://www.myrealm.com (‘http://www.myrealm.com’ or ‘we’ or ‘us’ or ‘our’) are committed to ensuring the security and protection of the personal information that we process, and to provide a compliant and consistent approach to data protection. We have always had a robust and effective data protection program in place which complies with existing law and abides by the data protection principles. However, we have updated and expanded this program to meet the demands of the GDPR and the UK’s Data Protection Bill.<br/>
                http://www.myrealm.com are dedicated to safeguarding the personal information under our remit and in developing a data protection regime that is effective, fit for purpose and demonstrates an understanding of, and appreciation for the new Regulation. Our preparation and objectives for GDPR compliance have been summarized in this statement and include the development and implementation of new data protection roles, policies, procedures, controls and measures to ensure maximum and ongoing compliance.

                </p><br/>

                <b>How we are preparing for the GDPR</b>

                <p>
                http://www.myrealm.com has a consistent level of data protection and security across our organization, and is fully compliant with GDPR regulation. This includes: – <br/>
                &#10003;Information Audit – carrying out a company-wide information audit to identify and assess what personal information we hold, where it comes from, how and why it is processed and if and to whom it is disclosed.<br/>
                &#10003;Policies & Procedures – revising data protection policies and procedures to meet the requirements and standards of the GDPR and any relevant data protection laws, including: –<br/>
                &#10003;Data Protection – our main policy and procedure document for data protection meets the standards and requirements of the GDPR. Accountability and governance measures are in place to ensure that we understand and adequately disseminate and evidence our obligations and responsibilities; with a dedicated focus on privacy by design and the rights of individuals.<br/>
                &#10003;Data Retention & Erasure – our retention policy and schedule ensure that we meet the ‘data minimization and ‘storage limitation’ principles and that personal information is stored, archived and destroyed compliantly and ethically. We have dedicated erasure procedures in place to meet the new ‘Right to Erasure’ obligation and are aware of when this and other data subject’s rights apply; along with any exemptions, response time frames and notification responsibilities.<br/>
                &#10003;Data Breaches – our breach procedures ensure that we have safeguards and measures in place to identify, assess, investigate and report any personal data breach at the earliest possible time. Our procedures are robust and have been disseminated to all employees, making them aware of the reporting lines and steps to follow.<br/>
                &#10003;International Data Transfers & Third-Party Disclosures – where http://www.myrealm.com stores or transfers personal information outside the EU, we have robust procedures and safeguarding measures in place to secure, encrypt and maintain the integrity of the data. Our procedures include a continual review of the countries with sufficient adequacy decisions, as well as provisions for binding corporate rules; standard data protection clauses or approved codes of conduct for those countries without. We carry out strict due diligence checks with all recipients of personal data to assess and verify that they have appropriate safeguards in place to protect the information, ensure enforceable data subject rights and have effective legal remedies for data subjects where applicable.<br/>
                &#10003;Subject Access Request (SAR) – our SAR procedures accommodate the 30-day time frame for providing the requested information and for making this provision free of charge. Our procedures detail how to verify the data subject, what steps to take for processing an access request, what exemptions apply and a suite of response templates to ensure that communications with data subjects are compliant, consistent and adequate.<br/>
                &#10003;Legal Basis for Processing – we are reviewing all processing activities to identify the legal basis for processing and ensuring that each basis is appropriate for the activity it relates to. Where applicable, we also maintain records of our processing activities, ensuring that our obligations under Article 30 of the GDPR and Schedule 1 of the Data Protection Bill are met.<br/>
                &#10003;Privacy Notice/Policy – our Privacy Notice(s) comply with the GDPR, ensuring that all individuals whose personal information we process have been informed of why we need it, how it is used, what their rights are, who the information is disclosed to and what safeguarding measures are in place to protect their information.<br/>
                &#10003;Direct Marketing – the wording and processes for direct marketing include a clear notice and method for opting out and providing unsubscribe features on all subsequent marketing materials.<br/>
                &#10003;Data Protection Impact Assessments (DPIA) – where we process personal information that is considered high risk, involves large scale processing or includes special category/criminal conviction data; we have stringent procedures and assessment templates for carrying out impact assessments that comply fully with the GDPR’s Article 35 requirements. We have implemented documentation processes that record each assessment, allow us to rate the risk posed by the processing activity and implement mitigating measures to reduce the risk posed to the data subject(s).<br/>
                &#10003;Processor Agreements – where we use any third-party to process personal information on our behalf (i.e. Payroll, Recruitment, Hosting etc), we have compliant Processor Agreements and due diligence procedures for ensuring that they (as well as we), meet and understand their/our GDPR obligations. These measures include initial and ongoing reviews of the service provided, the necessity of the processing activity, the technical and organisational measures in place and compliance with the GDPR.<br/>
                &#10003;Special Categories Data – where we obtain and process any special category information, we do so in complete compliance with the Article 9 requirements and have high-level encryption and protections on all such data. Special category data is only processed where necessary and is only processed where we have first identified the appropriate Article 9(2) basis or the Data Protection Bill Schedule 1 condition. Where we rely on consent for processing, this is explicit and is verified by a signature, with the right to modify or remove consent being clearly signposted.<br/>

                </p><br/>

                <b>Data subject rights</b>

                <p>
                In addition to the policies and procedures mentioned above that ensure individuals can enforce their data protection rights, we provide easy to access information via our website, in the office, during induction etc of an individual’s right to access any personal information that http://www.myrealm.com  processes about them and to request information about: –<br/>
                • What personal data we hold about them<br/>
                • The purposes of the processing<br/>
                • The categories of personal data concerned<br/>
                • The recipients to whom the personal data has/will be disclosed<br/>
                • How long we intend to store your personal data for<br/>
                • If we did not collect the data directly from them, information about the source<br/>
                • The right to have incomplete or inaccurate data about them corrected or completed and the process for requesting this<br/>
                • The right to request erasure of personal data (where applicable) or to restrict processing in accordance with data protection laws, as well as to object to any direct marketing from us and to be informed about any automated decision-making that we use<br/>
                • The right to lodge a complaint or seek judicial remedy and who to contact in such instances<br/>

                </p><br/>

                <b>Information security & technical and organization measures</b><br/>

                <p>
                http://www.myrealm.com  takes the privacy and security of individuals and their personal information very seriously and take every reasonable measure and precaution to protect and secure the personal data that we process. We have robust information security policies and procedures in place to protect personal information from unauthorized access, alteration, disclosure or destruction and have several layers of security measures, including: 
                </p><br/>

                <p>
                • Encryption using certificates from trusted certificate authorities<br/>
                • Security by design<br/>
                • Edge and internal firewalls to segregate roles<br/>
                • Segregation of datasets<br/>
                • Physical and digital access controls<br/>
                • A complex passphrase policy<br/>
                • Offsite backups<br/>
                • Regular patch cycle and reporting<br/>
                • Regular penetration and vulnerability testing by external specialists<br/><br/>
    
                </p>

                <p>
                http://www.myrealm.com have a designated Data Protection Officer (DPO) to develop and implement our road-map for complying with the new data protection Regulation. The DPO is responsible for promoting awareness of the GDPR across the organization, assessing our GDPR readiness, identifying any gap areas and implementing the new policies, procedures and measures. 
                </p><br/>

                <b>
                Contact US
                </b><br/>

                <p>
                If you have any questions about our preparation for the GDPR, please contact Data Protection Officer (DPO) –  info@myrealm.com    
                </p>














                </div>
             </div>       
        </div>
    </div>
    )
}

export default Policy
