import React, { useState, useEffect } from "react";
import PayPalBtn from "./paypal-btn";
import "./styles/paypal.scss";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";

const override = css`
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-top: 40vh;
`;

const paypalSubscribe = (data, actions) => {
  return actions.subscription.create({
    plan_id: "P-68Y41794E8117383EMBML2TA",
  });
};
const paypalOnError = (err) => {
  console.log("Error");
};
const paypalOnApprove = (data, detail) => {
  // call the backend api to store transaction details
  console.log(detail);
  window.location.replace("/register");
};

function Paypalbtn1() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const moment = require("moment");

  const todayDate = moment().format("MMMM Do YYYY");
  const dueDate = moment().add(30, "days").format("MMMM Do YYYY");

  return (
    <>
     {loading ? (
        <ScaleLoader
          css={override}
          size={150}
          color={"#7C0AB1"}
          loading={loading}
        />
      ) : (
        <div className="payment-wrapper">
      <div className="payment-div">
        <div className="payment-padding">
          <form>
            <div className="box1">
              <p className="order-summary">Order Summary</p>
              <hr />
            </div>
            <div className="box2">
              <p className="subscription">NEW SUBSCRIPTION</p>
              <p className="subscription">
                {todayDate} - {dueDate}
              </p>
            </div>

            <div className="box3">
              <p>Myrealm Premium</p>
              <p>$4.99</p>
            </div>
            <hr />
            <div className="box4">
              <p>Sub-total</p>
              <p>$4.99</p>
            </div>
            <hr />
            <div className="box5">
              <p>Total</p>
              <p>$4.99</p>
            </div>
            <hr />

            <div className="terms-div">
              <div className="terms">
                <input type="checkbox" required />
                <p>
                  &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                  <Link to="/termsandconditions" className="terms-conditions">
                    terms&nbsp;and&nbsp;conditions
                  </Link>
                  .
                </p>
              </div>

              <div className="terms">
                <input type="checkbox" required />
                <p>
                  &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                  <Link to="/privacypolicy" className="terms-conditions">
                    Privacy&nbsp;Policy
                  </Link>
                  .
                </p>
              </div>

              <div className="terms">
                <input type="checkbox" required />
                <p>
                  &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                  <Link to="/Disclaimer" className="terms-conditions">
                    Disclaimer
                  </Link>
                  .
                </p>
              </div>

              <div className="terms">
                <input type="checkbox" required />
                <p>
                  &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                  <Link to="/Gdpr" className="terms-conditions">
                    GDPR
                  </Link>
                  .
                </p>
              </div>
            </div>

            <div className="paypal-div">
              <PayPalBtn
                amount="4.99"
                currency="USD"
                createSubscription={paypalSubscribe}
                onApprove={paypalOnApprove}
                catchError={paypalOnError}
                onError={paypalOnError}
                onCancel={paypalOnError}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
      )}
    </>
    
  );
}
export default Paypalbtn1;
