import Axios from "axios";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import domain from "../../util/domain";
import "./Navbar.css";
import toggle from "./img/toggle.png"
import brand from "./img/brand.png"
import Scrollspy from "react-scrollspy";


function Navbar() {
  const [showMenu, setShowmenu] = useState(false)
  const { user, getUser } = useContext(UserContext);

  let menu 

  if(showMenu){
    menu = <div className="mobile-links">
              
              <div className="mobile-padding">
                
                <Link id="navlinks-mobile" className="" to="/"  onClick={() => setShowmenu(false)}><p>Home</p></Link>
                
                <Link id="navlinks-mobile" className="" to="/Membership" onClick={() => setShowmenu(false)}><p>Pricing</p></Link>
                
                <Link id="navlinks-mobile" className="" to="/" onClick={() => setShowmenu(false)}><p>Contact&nbsp;us</p></Link>
             
                <Link id="navlinks-mobile" className="btn-login" to="/login"  onClick={() => setShowmenu(false)}><p>Log&nbsp;in</p></Link>
                
                <div className="regmobile-div">
                  <Link className="btn-reg-mobile" to="/paypal" onClick={() => setShowmenu(false)}><p className="btn-register-mobile">Register</p></Link>
                  
                </div>
            
              </div>
            </div>
  }

  async function logOut() {
    await Axios.get(`${domain}/auth/logOut`);
    await getUser();
  }

  return (
  <div className="navbarwrapper"> 
    <div className="padding">
      
        <Link to="/"><img className="brandname1" src={brand} alt="title" /></Link>
    
    <div className="navbar">
      
         <img className="toggle" src={toggle} alt="toggle" onClick={() => setShowmenu(!showMenu)}/>
          
          <Link to="/"><img className="brandname2" src={brand} alt="title" /></Link>
     
      
      <div className="getbutton">
       <Link to="/paypal"><button >Grab&nbsp;it</button></Link>
      </div>
        
        
      {user === null ? (
      
        
        
          <div className="links">
            <Scrollspy id="navlinks" className="" ><a href="#homepage-padding" id="navlinks">Home</a></Scrollspy>
            <Scrollspy id="navlinks" className="" ><a href="#membershipspy" id="navlinks">Pricing</a></Scrollspy>
            <Scrollspy id="navlinks" className="" ><a href="#contact-form" id="navlinks">Contact&nbsp;us</a></Scrollspy>
            <Link id="navlinks" className="btn-login" to="/login"><p>Log&nbsp;in</p></Link>
            <Link className="btn-register" to="/paypal"><p>Register</p></Link>
          </div>
          
    
        
                      ) : (
                        user && (
                          <button className="btn-logout" onClick={logOut}>
                            Log out
                          </button>
                        )
                      )}
            
    </div>
    {menu}
    </div>
  </div>
  );
}

export default Navbar;
