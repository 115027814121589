import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./SnippetEditor.scss";
import ErrorMessage from "../misc/ErrorMessage";
import domain from "../../util/domain";

function SnippetEditor({ getSnippets, setSnippetEditorOpen, editSnippetData }) {
  const [editorTitle, setEditorTitle] = useState("");
  const [editorDescription, setEditorDescription] = useState("");
  const [editorCode, setEditorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (editSnippetData) {
      setEditorTitle(editSnippetData.title ? editSnippetData.title : "");
      setEditorDescription(editSnippetData.description ? editSnippetData.description : "");
      setEditorCode(editSnippetData.code ? editSnippetData.code : "");
    }
  }, [editSnippetData]);

  async function saveSnippet(e) {
    e.preventDefault();

    const snippetData = {
      title: editorTitle ? editorTitle : undefined,
      description: editorDescription ? editorDescription : undefined,
      code: editorCode ? editorCode : undefined,
    };

    try {
      if (!editSnippetData) await Axios.post(`${domain}/snippetmedias/`, snippetData);
      else
        await Axios.put(
          `${domain}/snippetmedias/${editSnippetData._id}`,
          snippetData
        );
    } catch (err) {
      if (err.response) {
        if (err.response.data.errorMessage) {
          setErrorMessage(err.response.data.errorMessage);
        }
      }
      return;
    }

    getSnippets();
    closeEditor();
  }

  function closeEditor() {
    setSnippetEditorOpen(false);
    setEditorCode("");
    setEditorDescription("");
    setEditorTitle("");
  }

  return (
  <div className="snippet-editor-div">
    <div className="snippet-editor"> 
        
        <div className="Snippeteditor-padding">
            
          {errorMessage && ( <ErrorMessage message={errorMessage} clear={() => setErrorMessage(null)}/>)}
              <form className="form" onSubmit={saveSnippet}>
                 
                <input placeholder="Media name" id="editor-title" type="text" value={editorTitle}
                    onChange={(e) => setEditorTitle(e.target.value)} /><br />
                
                <input placeholder="Note" id="editor-description" type="text" value={editorDescription}
                    onChange={(e) => setEditorDescription(e.target.value)}/><br />

                <input placeholder="Email" id="editor-code" type="email" value={editorCode}
                    onChange={(e) => setEditorCode(e.target.value)}/><br />

                <div className="editorbtn-div">
                    <button className="btn-save" type="submit">
                      Save
                    </button>
                    
                    <button className="btn-cancel" type="button" onClick={closeEditor}>
                      Cancel
                    </button>
                </div>

              </form>
        </div>

    </div>
  </div>
  );
}

export default SnippetEditor;
