import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import banner1 from "./img/background1.png";
import banner2 from "./img/background2.png";
import banner3 from "./img/background3.png";
import userImage from "./img/user.png";
import Scrollspy from "react-scrollspy";

import "boxicons";

//snippet
import Snippetwebsites from "./Snippetwebsites";
import Snippetinsurances from "./Snippetinsurances";
import Snippetbanks from "./Snippetbanks";
import Snippetmedias from "./Snippetmedias";

//snippeteditor
import SnippetEditorwebsites from "./SnippetEditorwebsites";
import SnippetEditorinsurances from "./SnippetEditorinsurances";
import SnippetEditorbanks from "./SnippetEditorbanks";
import SnippetEditormedias from "./SnippetEditormedias";

import "./styles/Home-extra-small-device.css";
import "./styles/Home-small-device.css";
import "./styles/Home-medium-device.css";
import "./styles/Home-large-device.css";
import "./styles/Home-extra-large-device.css";
import "./styles/Sidebar.css";
import "./styles/Mainscreen.css";


import UserContext from "../../context/UserContext";
import { Link } from "react-router-dom";
import domain from "../../util/domain";
import Navbar from "../misc/Navbar";
import Footer from "../misc/Footer";

function Home() {
  //Website
  const [snippets, setSnippets] = useState([]);
  const [snippetEditorOpen, setSnippetEditorOpen] = useState(false);
  const [editSnippetData, setEditSnippetData] = useState(null);

  //insurances
  const [snippetsinsurances, setSnippetsinsurances] = useState([]);
  const [snippetEditorOpeninsurance, setsnippetEditorOpeninsurance] =
    useState(false);
  const [editSnippetDatainsurances, setEditSnippetDatainsurances] =
    useState(null);

  //banks
  const [snippetsbanks, setSnippetsbanks] = useState([]);
  const [snippetEditorOpenbanks, setsnippetEditorOpenbanks] = useState(false);
  const [editSnippetDatabanks, setEditSnippetDatabanks] = useState(null);

  //medias
  const [snippetsmedias, setSnippetsmedias] = useState([]);
  const [snippetEditorOpenmedias, setSnippetEditorOpenmedias] = useState(false);
  const [editSnippetDatamedias, setEditSnippetDatamedias] = useState(null);

  const { user, getUser } = useContext(UserContext);

  //websites
  useEffect(() => {
    if (!user) setSnippets([]);
    else getSnippets();
  }, [user]);

  //insurances
  useEffect(() => {
    if (!user) setSnippetsinsurances([]);
    else getSnippetsinsurances();
  }, [user]);

  //banks
  useEffect(() => {
    if (!user) setSnippetsbanks([]);
    else getSnippetsbanks();
  }, [user]);

  //medias
  useEffect(() => {
    if (!user) setSnippetsmedias([]);
    else getSnippetsmedias();
  }, [user]);

  //websites
  async function getSnippets() {
    const snippetsRes = await Axios.get(`${domain}/snippetwebsites/`);
    setSnippets(snippetsRes.data);
  }

  //insurances
  async function getSnippetsinsurances() {
    const snippetsResinsurances = await Axios.get(
      `${domain}/snippetinsurances/`
    );
    setSnippetsinsurances(snippetsResinsurances.data);
  }

  //banks
  async function getSnippetsbanks() {
    const snippetsResbanks = await Axios.get(`${domain}/snippetbanks/`);
    setSnippetsbanks(snippetsResbanks.data);
  }

  //medias
  async function getSnippetsmedias() {
    const snippetsResmedias = await Axios.get(`${domain}/snippetmedias/`);
    setSnippetsmedias(snippetsResmedias.data);
  }

  //website
  function editSnippet(snippetData) {
    setEditSnippetData(snippetData);
    setSnippetEditorOpen(true);
  }

  //insurances
  function editSnippetinsuraces(snippetDatainsurances) {
    setEditSnippetDatainsurances(snippetDatainsurances);
    setsnippetEditorOpeninsurance(true);
  }

  //banks
  function editSnippetbanks(snippetDatabanks) {
    setEditSnippetDatabanks(snippetDatabanks);
    setsnippetEditorOpenbanks(true);
  }

  //medias
  function editSnippetmedias(snippetDatamedias) {
    setEditSnippetDatamedias(snippetDatamedias);
    setSnippetEditorOpenmedias(true);
  }

  //website
  function renderSnippets() {
    let sortedSnippets = [...snippets];
    sortedSnippets = sortedSnippets.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return sortedSnippets.map((snippet, i) => {
      return (
        <Snippetwebsites
          key={i}
          snippet={snippet}
          getSnippets={getSnippets}
          editSnippet={editSnippet}
        />
      );
    });
  }

  //insurances
  function renderSnippetsinsurances() {
    let sortedSnippets = [...snippetsinsurances];
    sortedSnippets = sortedSnippets.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return sortedSnippets.map((snippetinsurances, i) => {
      return (
        <Snippetinsurances
          key={i}
          snippet={snippetinsurances}
          getSnippets={getSnippetsinsurances}
          editSnippet={editSnippetinsuraces}
        />
      );
    });
  }

  //banks
  function renderSnippetsbanks() {
    let sortedSnippets = [...snippetsbanks];
    sortedSnippets = sortedSnippets.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return sortedSnippets.map((snippetbanks, i) => {
      return (
        <Snippetbanks
          key={i}
          snippet={snippetbanks}
          getSnippets={getSnippetsbanks}
          editSnippet={editSnippetbanks}
        />
      );
    });
  }

  //medias
  function renderSnippetsmedias() {
    let sortedSnippets = [...snippetsmedias];
    sortedSnippets = sortedSnippets.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return sortedSnippets.map((snippetmedias, i) => {
      return (
        <Snippetmedias
          key={i}
          snippet={snippetmedias}
          getSnippets={getSnippetsmedias}
          editSnippet={editSnippetmedias}
        />
      );
    });
  }

  let sidebar = document.querySelector(".sidebar");
  let closeBtn = document.querySelector("#btn");
  function opensidebar() {
    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
      menuBtnChange(); //calling the function(optional)
    });
  }

  function menuBtnChange() {
    if (sidebar.classList.contains("open")) {
      closeBtn.classList.replace("bx-right-arrow-alt", "bx-arrow-back"); //replacing the iocns class
    } else {
      closeBtn.classList.replace("bx-arrow-back", "bx-right-arrow-alt"); //replacing the iocns class
    }
  }

  async function logOut() {
    await Axios.get(`${domain}/auth/logOut`);
    await getUser();
  }

  return (
    <div className="home">
      {!snippetEditorOpen &&
        !snippetEditorOpeninsurance &&
        !snippetEditorOpenbanks &&
        !snippetEditorOpenmedias &&
        user && (
          <div className="Home2-wrapper">
            <div className="Home2-wrapper-padding">
              
              <div className="sidebar">
                <div className="logo-details">
                  <div className="logo_name">Myrealm</div>
                  <Link onClick={opensidebar}>
                    <i className="bx bx-right-arrow-alt" id="btn"></i>
                  </Link>
                </div>
                <ul className="nav-list">
                  <li>
                    <a href="#">
                      <i class="bx bx-home-alt"></i>
                      <span className="links_name">All items</span>
                    </a>
                    <span className="tooltip">All&nbsp;items</span>
                  </li>
                  <li>
                    <a href="#">
                      <i class="bx bx-globe"></i>
                      <span className="links_name">Websites</span>
                    </a>
                    <span className="tooltip">Websites</span>
                  </li>
                  <li>
                    <a href="#">
                      <i class="bx bx-money"></i>
                      <span className="links_name">Insurance</span>
                    </a>
                    <span className="tooltip">Insurance</span>
                  </li>
                  <li>
                    <a href="#">
                      <i class="bx bxs-bank"></i>
                      <span className="links_name">Banks</span>
                    </a>
                    <span className="tooltip">Banks</span>
                  </li>
                  <li>
                    <a href="#">
                      <i className="bx bx-folder"></i>
                      <span className="links_name">Media</span>
                    </a>
                    <span className="tooltip">Media</span>
                  </li>

                  <li className="profile">

                    
                    <div className="profile-details">
                      <img src={userImage} alt="profileImg" />
                      <div className="name_job">
                        <div className="name">Welcome back!</div>
                        <div className="job">
                     
                          <a id="unsub" target="blank" href="https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias=PB9NEWY4CJCJJ" >Unsubscribe</a> 
                     
                        </div>

                      </div>
                    </div>

                    {user === null ? (
                      <></>
                    ) : (
                      user && (
                        <Link onClick={logOut}>
                          <i className='bx bx-log-out-circle bx-md' id='log_out'></i>
                        </Link>
                      )
                    )}
                  </li>
                </ul>
              </div>

              <section className="home-section">
                <div className="Dashboard">
                  <h1>Dashboard</h1>
                </div>
                <div className="home-section-padding">

                  <div className="home-body1">
                    <div className="additems-btn">
                      <h1 className="website-label">Websites</h1>
                      <button
                        className="btn-editor-toggle"
                        onClick={() => setSnippetEditorOpen(true)}
                      >
                      <i class='bx bx-plus' ></i>  Add Items
                      </button>
                    </div>
                    <hr className="home-hr1" />
                    <br />

                    <div className="snippet-div-1">
                      {snippets.length > 0
                        ? renderSnippets()
                        : user && (
                            <div className="no-item-div">
                              <p className="no-snippets-msg">
                                No items have been
                                <br /> added yet.
                              </p>
                              <div className="no-items-div-1">
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                              </div>
                            </div>
                          )}
                    </div>
                  </div>

                  <div className="home-body2">
                    <div className="additems-btn">
                      <h1 className="website-label">Insurances</h1>
                      <button
                        className="btn-editor-toggle"
                        onClick={() => setsnippetEditorOpeninsurance(true)}
                      >
                      <i class='bx bx-plus' ></i>  Add Items
                      </button>
                    </div>
                    <hr className="home-hr1" />
                    <br />

                    <div className="snippet-div-1">
                      {snippetsinsurances.length > 0
                        ? renderSnippetsinsurances()
                        : user && (
                            <div className="no-item-div">
                              <p className="no-snippets-msg">
                                No items have been
                                <br /> added yet.
                              </p>
                              <div className="no-items-div-1">
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                              </div>
                            </div>
                          )}
                    </div>
                  </div>

                  <div className="home-body3">
                    <div className="additems-btn">
                      <h1 className="website-label">Banks</h1>
                      <button
                        className="btn-editor-toggle"
                        onClick={() => setsnippetEditorOpenbanks(true)}
                      >
                       <i class='bx bx-plus' ></i> Add Items
                      </button>
                    </div>
                    <hr className="home-hr1" />
                    <br />

                    <div className="snippet-div-1">
                      {snippetsinsurances.length > 0
                        ? renderSnippetsbanks()
                        : user && (
                            <div className="no-item-div">
                              <p className="no-snippets-msg">
                                No items have been
                                <br /> added yet.
                              </p>
                              <div className="no-items-div-1">
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                              </div>
                            </div>
                          )}
                    </div>
                  </div>

                  <div className="home-body4">
                    <div className="additems-btn">
                      <h1 className="website-label">Medias</h1>
                      <button
                        className="btn-editor-toggle"
                        onClick={() => setSnippetEditorOpenmedias(true)}
                      >
                       <i class='bx bx-plus' ></i> Add Items
                      </button>
                    </div>
                    <hr className="home-hr1" />
                    <br />

                    <div className="snippet-div-1">
                      {snippetsmedias.length > 0
                        ? renderSnippetsmedias()
                        : user && (
                            <div className="no-item-div">
                              <p className="no-snippets-msg">
                                No items have been
                                <br /> added yet.
                              </p>
                              <div className="no-items-div-1">
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                                <div className="noitems-div1"></div>
                              </div>
                            </div>
                          )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}

      {snippetEditorOpen && (
        <SnippetEditorwebsites
          setSnippetEditorOpen={setSnippetEditorOpen}
          getSnippets={getSnippets}
          editSnippetData={editSnippetData}
        />
      )}

      {snippetEditorOpeninsurance && (
        <SnippetEditorinsurances
          setSnippetEditorOpen={setsnippetEditorOpeninsurance}
          getSnippets={getSnippetsinsurances}
          editSnippetData={editSnippetDatainsurances}
        />
      )}

      {snippetEditorOpenbanks && (
        <SnippetEditorbanks
          setSnippetEditorOpen={setsnippetEditorOpenbanks}
          getSnippets={getSnippetsbanks}
          editSnippetData={editSnippetDatabanks}
        />
      )}

      {snippetEditorOpenmedias && (
        <SnippetEditormedias
          setSnippetEditorOpen={setSnippetEditorOpenmedias}
          getSnippets={getSnippetsmedias}
          editSnippetData={editSnippetDatamedias}
        />
      )}

      {user === null && (
        <div className="homepage-wrap" id="homepage-wrap">
          <Navbar />
          <div className="homepage-padding" id="homepage-padding">
            <div className="section_1">
              <div className="section-1-padding">
                <div className="Front-banner-text">
                  <div className="Square-back"></div>
                  <h1>Easy and secure access to all of your account</h1>

                  <div className="Front-banner-button">
                    <Link to="/paypal">
                      <p className="Front-banner-button-register">Register</p>
                    </Link>
                    <Scrollspy>
                      <a
                        href="#membershipspy"
                        className="Front-banner-button-seepricing"
                      >
                        See pricing
                      </a>
                    </Scrollspy>
                  </div>
                </div>
                <div className="Front-banner-div">
                  <img className="Front-banner-div-img" src={banner1} alt="" />
                </div>
              </div>
            </div>

            <div className="section_2">
              <div className="section-2-padding">
                <div className="Front-banner-div-2-duplicate">
                  <img
                    className="Front-banner-div-img-2-duplicate"
                    src={banner2}
                    alt=""
                  />
                </div>
                <div className="Front-banner-text-2">
                  <div className="Square-back-2"></div>
                  <h1>
                    Secured system using latest technology in terms of security
                  </h1>
                </div>
                <div className="Front-banner-div-2">
                  <img
                    className="Front-banner-div-img-2"
                    src={banner2}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="section_3">
              <div className="section-3-padding">
                <div className="Front-banner-text-3">
                  <div className="Square-back-3"></div>
                  <h1>Readily Mobile responsive for all types of gadgets</h1>
                </div>
                <div className="Front-banner-div-3">
                  <img
                    className="Front-banner-div-img-3"
                    src={banner3}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Home padding*/}
          <div id="membershipspy"></div>
          <div className="membershipwrapper">
            <h1 className="pricing">Our Pricing</h1>
            <p className="pricing-text">
              No commercial use of your personal data therefore it’s paid.
            </p>

            <div className="pricing-padding">
              <div className="membershipdiv1">
                <div className="memberpadding">
                  <h1 className="free"> MONTHLY PLAN </h1>
                  <h1 className="price-dollar">$4.99/Month</h1>

                  <div className="details1">
                    <p className="plandetails">
                      Monthly plan of Myrealm <br /> consists of basic and
                      <br /> advance features.
                    </p>
                  </div>

                  <button className="price-button1">
                    <Link to="/paypal">
                      <p>Select</p>
                    </Link>
                  </button>
                </div>
              </div>

              <div className="membershipdiv2">
                <div className="memberpadding">
                  <h1 className="monthly"> FREE PLAN</h1>
                  <h1 className="price-dollar2">$0/Month</h1>
                  <div className="details2">
                    <p className="plandetails2">
                      Free plan of Myrealm <br /> limited to basic
                      <br /> features only.
                    </p>
                  </div>

                  <button className="price-button2">
                    <Link to="/register">
                      <p>Select</p>
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <form className="contact-form" id="contact-form">
              <h1 className="contact-us">Contact us</h1>
              <div className="form-group1">
                <input
                  type="text"
                  className="form-control1"
                  placeholder="Name"
                />

                <input
                  type="email"
                  className="form-control1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                />
              </div>

              <div className="form-group2">
                <input
                  type="text"
                  placeholder="Subject"
                  className="form-control2"
                />

                <textarea
                  className="form-control2-message"
                  rows="5"
                  placeholder="Message"
                />

                <div className="terms-div">
                  <div className="terms">
                    <input type="checkbox" required />
                    <p style={{ color: "#ffffff" }}>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link
                        to="/termsandconditions"
                        className="terms-conditions"
                      >
                        terms&nbsp;and&nbsp;conditions
                      </Link>
                      .
                    </p>
                  </div>

                  <div className="terms">
                    <input type="checkbox" required />
                    <p style={{ color: "#ffffff" }}>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link to="/privacypolicy" className="terms-conditions">
                        Privacy&nbsp;Policy
                      </Link>
                      .
                    </p>
                  </div>

                  <div className="terms">
                    <input type="checkbox" required />
                    <p style={{ color: "#ffffff" }}>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link to="/Disclaimer" className="terms-conditions">
                        Disclaimer
                      </Link>
                      .
                    </p>
                  </div>

                  <div className="terms">
                    <input type="checkbox" required />
                    <p style={{ color: "#ffffff" }}>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link to="/Gdpr" className="terms-conditions">
                        GDPR
                      </Link>
                      .
                    </p>
                  </div>
                </div>

                <div className="regbutton">
                  <button className="btn-submit" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <Footer />
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
