import { PayPalButton } from "react-paypal-button-v2";
import React from 'react';

export function PayPalBtn(props) {
const { amount, currency, createSubscription, onApprove, catchError,onError, onCancel} = props;
const paypalKey = "AUtxV-vVypDLOU4lB6FT4OizCoSEKcwSeTHDapkuXlNnRS01Hg4ZgVG3Synd4IZDPxAHzJuE6yiLUWZM"

return (
<PayPalButton
        amount={amount}
        currency={currency}
        createSubscription={(data, details) => createSubscription(data, details)}
        onApprove={(data, details) => onApprove(data, details)}
        onError={(err) => onError(err)}
        catchError={(err) => catchError(err)}
        onCancel={(err) => onCancel(err)}
        options={{
            clientId: paypalKey,
            vault:true
        }}
        style={{
            shape: 'pill',
            color: 'blue',
            label: 'subscribe',
        }}
/>
);
}
export default PayPalBtn;