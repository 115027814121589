import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Membership from "./components/misc/Membership"
import Home from "./components/home/Home";
import Paypalbtn1 from "../src/components/home/paypal";
import Policy from "../src/components/misc/Policy";
import Termsandconditions from "../src/components/misc/Termsandconditions";
import Disclaimer from "../src/components/misc/Disclaimer";
import Gdpr from "../src/components/misc/Gdpr";



function Router() {
  return (
    <BrowserRouter>
      
      <Switch>
        
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/Membership">
          <Membership />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/paypal">
          <Paypalbtn1 />
        </Route>
        <Route path="/privacypolicy">
          <Policy />
        </Route>
        <Route path="/termsandconditions">
          <Termsandconditions />
        </Route>
        <Route path="/Disclaimer">
          <Disclaimer />
        </Route>
        <Route path="/GDPR">
          <Gdpr />
        </Route>
        
      </Switch>
      
    </BrowserRouter>
  );
}

export default Router;
