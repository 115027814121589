import React from 'react';
import Navbar from "./Navbar";
import "./policy.css";


function Policy() {
    return (
        
    <div className="main_wrapper">
        <Navbar/>
        <div className="policy_wrapper">
            
            <div className="policy_div">
                <div className="policy_padding">
                    <h2>Terms and conditions</h2><br/>
                
                    <p>
                    These terms and conditions ("Agreement") set forth the general terms and conditions of your use of the "http://www.myrealm.com" Website ("Website" or "Service") and any of its related products and services (collectively, "Services"). This Agreement is legally binding between you ("User", "you" or "your") and this Website developer ("Operator", "we", "us" or "our"). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Website and Services. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services. 
                    </p><br/>

                    <h3>Accounts and membership</h3>
                    
                    <p>
                    If you create an account in the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.
                    </p><br/>

                    <h3>Links to other resources</h3>

                    <p>
                    Although the Website and Services may link to other resources (such as websites, Websites, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some of the links in the Website may be "affiliate links". This means if you click on the link and purchase an item, the Operator will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link in the Website and Services. Your linking to any other off-site resources is at your own risk.
                    </p><br/>


                    <h3>Changes and amendments</h3>
                    <p>
                    We reserve the right to modify this Agreement or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Agreement in the Website. When we do, we will post a notification in the Website. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.
                    </p><br/>

                    <h3>Right to information, correction, blocking, deletion</h3>
                    <p>
                    Within the scope of the legal provisions you have the right to free information about your stored personal data, the origin of the data, their recipients and the purpose of the data processing. Possibly. You also have the right to correct, block or delete this data. In this regard and also to further questions on the subject of personal data, you can always contact us via the contact options listed in the imprint.
                    </p><br/>

                    <h3>SSL or TLS encryption</h3>
                    <p>
                    For security reasons and to protect the transmission of confidential content that you send to us as a site operator, our website uses an SSL or. TLS encryption. Thus, data that you submit via this website, for others not readable. You will recognize an encrypted connection at the “https: //” address bar of your browser and at the lock icon in the browser bar.
                    </p><br/>

                    <h3>Server log files</h3>
                    <p>
                    For security reasons and to protect the transmission of confidential content that you send to us as a site operator, our website uses an SSL or. TLS encryption. Thus, data that you submit via this website, for others not readable. You will recognize an encrypted connection at the “https: //” address bar of your browser and at the lock icon in the browser bar.
                    </p><br/>

                    <h3>Server log files</h3>
                    <p>
                    The provider of the website automatically saves information in its log files that your browser automatically sends to us. These are among others:<br/>
                    </p><br/>
                    
                    <ul>
                        <li>&#8226; Date and time of the server request</li>
                        <li>&#8226; Your browser type and browser version</li>
                        <li>&#8226; The operating system you are using</li>
                        <li>&#8226; The pages you visit on our domain</li>
                        <li>&#8226; Reference URL</li>
                        <li>&#8226; Host name of the accessing computer</li>
                        <li>&#8226; Your IP address</li>
                    </ul><br/>

                    <p> 
                    There is no merge of this data with other data sources. The basis for data processing is Art. 6 DSGVO. It allows data processing for contractual or precontractual activities.
                    </p><br/>

                    <h3>Contact form</h3>
                    <p>
                    Data submitted via the contact form will be stored, including your contact details, to process your request or to be available for follow-up questions. A disclosure of this data will not take place without your consent.
                    <br/><br/>
                    The processing of the data entered into the contact form takes place exclusively on the basis of your consent (Art. 6 (1) a DSGVO). A revocation of your already given consent is possible at any time. For the revocation is sufficient an informal message by e-mail. The legality of the data processing operations carried out until the revocation remains unaffected by the revocation.
                    <br/><br/>
                    Data submitted via the contact form will remain with us until you ask us to delete it, revoke your consent to storage, or you no longer need to back up your data. Mandatory statutory provisions – especially retention periods – remain unaffected.
                    </p>
                </div>
            </div>
            
        </div>
    </div>
    )
}

export default Policy
