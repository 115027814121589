import Axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";
import domain from "../../util/domain";
import ErrorMessage from "../misc/ErrorMessage";
import "./login.css";
import Navbar from "../misc/Navbar";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";

const override = css`
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-top: 40vh;
`;
function Login() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const { getUser } = useContext(UserContext);

  const history = useHistory();

  async function login(e) {
    e.preventDefault();

    const loginData = {
      email: formEmail,
      password: formPassword,
    };

    try {
      await Axios.post(`${domain}/auth/login`, loginData);
    } catch (err) {
      if (err.response) {
        if (err.response.data.errorMessage) {
          setErrorMessage(err.response.data.errorMessage);
        }
      }
      return;
    }

    await getUser();
    history.push("/");
  }

  return (
    <div className="loginwrapper">
      {loading ? (
        <ScaleLoader
          css={override}
          size={150}
          color={"#7C0AB1"}
          loading={loading}
        />
      ) : (
        <div className="loginwrapper">
          <Navbar />
          <div className="auth-padding">
            <h2 className="loginlabel">Login</h2>

            {errorMessage && (
              <ErrorMessage
                message={errorMessage}
                clear={() => setErrorMessage(null)}
              />
            )}
            <div className="formdiv">
              <form className="form" onSubmit={login}>
                <input
                  className="login-form-email"
                  id="login-form-email"
                  type="email"
                  placeholder="Email"
                  value={formEmail}
                  onChange={(e) => setFormEmail(e.target.value)}
                />
                <br />

                <input
                  className="login-form-password"
                  id="login-form-password"
                  type="password"
                  placeholder="Password"
                  value={formPassword}
                  onChange={(e) => setFormPassword(e.target.value)}
                />

                <div className="regdiv">
                  <p className="text">
                    Need&nbsp;account?&nbsp;
                    <Link className="reglink" to="/register">
                      Register&nbsp;here.
                    </Link>
                  </p>
                </div>

                <div className="loginbutton">
                  <button className="login" type="submit">
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
