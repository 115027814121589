import Axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";
import domain from "../../util/domain";
import ErrorMessage from "../misc/ErrorMessage";
import Navbar from "../misc/Navbar";
import "./register.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";

const override = css`
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-top: 40vh;
`;

function Register() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formPasswordVerify, setFormPasswordVerify] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const { getUser } = useContext(UserContext);

  const history = useHistory();

  async function register(e) {
    e.preventDefault();

    const registerData = {
      email: formEmail,
      password: formPassword,
      passwordVerify: formPasswordVerify,
    };

    try {
      await Axios.post(`${domain}/auth/`, registerData);
    } catch (err) {
      if (err.response) {
        if (err.response.data.errorMessage) {
          setErrorMessage(err.response.data.errorMessage);
        }
      }
      return;
    }

    await getUser();
    history.push("/");
  }

  return (
    <>
      {loading ? (
        <ScaleLoader
          css={override}
          size={150}
          color={"#7C0AB1"}
          loading={loading}
        />
      ) : (
        <div className="registerwrapper">
          <Navbar />
          <div className="register-auth-form">
            <h2 className="registerlabel">Register</h2>

            {errorMessage && (
              <ErrorMessage
                message={errorMessage}
                clear={() => setErrorMessage(null)}
              />
            )}
            <div className="regformdiv">
              <form className="form" onSubmit={register}>
                <input
                  id="register-form-email"
                  type="email"
                  placeholder="Email"
                  value={formEmail}
                  onChange={(e) => setFormEmail(e.target.value)}
                />
                <br />

                <input
                  id="register-form-password"
                  type="password"
                  placeholder="Password"
                  value={formPassword}
                  onChange={(e) => setFormPassword(e.target.value)}
                />
                <br />

                <input
                  id="register-form-passwordVerify"
                  type="password"
                  placeholder="Confirm Password"
                  value={formPasswordVerify}
                  onChange={(e) => setFormPasswordVerify(e.target.value)}
                />

                <div className="logindiv">
                  <p className="textlogin">
                    Already&nbsp;have&nbsp;an&nbsp;account?&nbsp;
                    <Link className="loginlink" to="/login">
                      Login&nbsp;instead
                    </Link>
                  </p>
                </div>

                <div className="terms-div">
                  <div className="terms">
                    <input type="checkbox" required />
                    <p>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link
                        to="/termsandconditions"
                        className="terms-conditions"
                      >
                        terms&nbsp;and&nbsp;conditions
                      </Link>
                      .
                    </p>
                  </div>

                  <div className="terms">
                    <input type="checkbox" required />
                    <p>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link to="/privacypolicy" className="terms-conditions">
                        Privacy&nbsp;Policy
                      </Link>
                      .
                    </p>
                  </div>

                  <div className="terms">
                    <input type="checkbox" required />
                    <p>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link to="/Disclaimer" className="terms-conditions">
                        Disclaimer
                      </Link>
                      .
                    </p>
                  </div>

                  <div className="terms">
                    <input type="checkbox" required />
                    <p>
                      &nbsp;I&nbsp;agree&nbsp;to&nbsp;the&nbsp;
                      <Link to="/Gdpr" className="terms-conditions">
                        GDPR
                      </Link>
                      .
                    </p>
                  </div>
                </div>

                <div className="regbutton">
                  <button className="btn-submit" type="submit">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Register;
