import React from 'react';
import Navbar from "./Navbar";
import "./policy.css";


function Policy() {
    return (
        
    <div className="main_wrapper">
        <Navbar/>
        <div className="policy_wrapper">
            
            <div className="policy_div">
                <div className="policy_padding">

                    <h2>Disclaimer</h2><br/>

                    <b>Interpretation and Definitions</b><br/><br/>

                    <b>Interpretation</b>
                    
                    <p>
                    The word which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                    </p><br/>

                    <b>Definitions.</b>

                    <p>
                    For the purpose of this Disclaimer:
                    </p><br/>
                    
                    <p>
                    •	Company (refer to as either “the Company”, “We”. “Us” or “Our” in this Disclaimer) refer to myrealm.com.<br/>
                    •	Service refers to the Website.<br/>
                    •	You means the individual accessing the Service, or the company, or the legal entity on behalf of which such individual is accessing or using the Service, as applicable.<br/>
                    •	Website means the software program provided by the /Website downloaded by You on any electronic device (Mobile, Laptop, or Desktop) named http;//www.http://www.myrealm.com.ch<br/>
                    </p><br/>

                    <b>Disclaimer</b>
                    <p>
                    The information contained on the Service is for general information purpose only.<br/><br/>
                    The Company assumes no responsibility for errors or omissions in the contents of Service.
                    In no event shall the Company be liable for any special direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort arising out of or in connection with the use of Service or the contents of service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
                    The Company does not warrant that the Service is free of viruses or other harmful components.

                    </p><br/>

                    <b>External Links Disclaimer</b>

                    <p>
                    The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
                    </p><br/>
                    
                    <b>
                    Errors and Omissions Disclaimer   
                    </b><br/>

                    <p>
                    The information given by the service is general guidance on matters of interest only. Even if the Company takes every precautions to insure that the content of the Service is both current and accurate, error can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.<br/><br/>
                    The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.
                    </p><br/>

                    <b>Fair Use Disclaimer</b>
                    <p>
                    The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comments, news reporting, teaching, scholarship or research.<br/><br/>
                    The company believes this constitutes a “fair use” of any such copyrighted material as provided for in section 107 of the United States copyright law.<br/><br/>
                    If you wish to copyrighted material from the Service for your own purpose that go beyond fair use, You must obtain permission from copyright owner.<br/><br/>
                    </p>

                    <b>Views Expressed Disclaimer</b>

                    <p>
                    The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.<br/><br/>
                    Comments, messages, videos, photos, or documents publishing by users are their sole responsibilities and the user will take full responsibility, liability and blame for any libel or litigation that results from something written in a comment. The Company is not liable for any comment published by user and reserve the right to delete any comment for any reason whatsoever.

                    </p><br/>

                    <b>No Responsibility Disclaimer</b>

                    <p>
                    The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and
                    services. As such, it should not be used as a substitutes for consultation with professional accounting, tax, legal or other consultation advisers.<br/><br/>
                    In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.

                    </p><br/>

                    <b>“Use at Your Own Risk” Disclaimer</b>

                    <p>
                    All information in the Service is provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.<br/><br/>
                    The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.

                    </p><br/>

                    <b>Contact US</b>

                    <p>
                    If you have any questions about this disclaimer, You can Contact US
                    By Email: info@myrealm.com

                    </p>
                </div>
             </div>       
        </div>
    </div>
    )
}

export default Policy
