import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

export default function Footer() {
    return (
        <div className="Footer-wrapper">
            <div className="Footer-padding">

            <div className="Footer-copyright">
                <h1>©2021 Myrealm™ All rights reserved.</h1>
            </div>

            </div>
        </div>
    )
}
