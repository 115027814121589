import Axios from "axios";
import { UserContextProvider } from "./context/UserContext";
import Router from "./Router";
import "./style/index.scss";
import React, { useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";

Axios.defaults.withCredentials = true;

const override = css`
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
  justify-content:center;
  margin-top: 40vh;
`;

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <UserContextProvider>
      {loading ? (
        <ScaleLoader
          css={override}
          size={150}
          color={"#7C0AB1"}
          loading={loading}
        />
      ) : (
        <Router />
      )}
    </UserContextProvider>
  );
}

export default App;
